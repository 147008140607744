import React from "react";
import AppBar from "@material-ui/core/AppBar";
import DropdownMenu from "./DropDownMenu";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { withTheme } from "styled-components";
import madaclinicsLogo from "../../assets/images/madaclinics-logo-new.svg";

import {
  NavContainer,
  CustomButton,
  LogoContainer,
  ButtonsContainer,
  DropDownContainer,
  DonateButton,
} from "./styles";

const ElevationScroll = (props) => {
  const { children } = props;

  // Detect user scroll to activate the navbar dropshadow
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  // Create clone of navbar for applying dropshadow effect on scroll
  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
  });
};

const NavBar = () => {
  return (
    <div style={{ width: "100%" }}>
      <ElevationScroll>
        <AppBar color="inherit">
          <NavContainer>
            <LogoContainer>
              <a href="/">
                <img src={madaclinicsLogo} alt="Madaclinics Logo" />
              </a>
            </LogoContainer>
            <ButtonsContainer>
              <CustomButton href="/">Home</CustomButton>
              <CustomButton href="/volunteer">Volunteer</CustomButton>
              <CustomButton href="/pictures">Pictures</CustomButton>
              <CustomButton href="/initiatives">Initiatives</CustomButton>
              <CustomButton href="/contact">Contact</CustomButton>
              <DropDownContainer>
                <DropdownMenu />
              </DropDownContainer>
            </ButtonsContainer>
            <DonateButton href="/donation">Donate</DonateButton>
          </NavContainer>
        </AppBar>
      </ElevationScroll>
    </div>
  );
};

export default withTheme(NavBar);
