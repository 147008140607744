import styled from "styled-components";
import Box from "@material-ui/core/Box";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import {
  smallScreen,
  mediumScreen,
  mediumLargeScreen,
  // largeScreen,
} from "../../utils/breakpoints";

const DashboardButton = styled.a`
  color: ${({ theme }) => theme.brand.dark};
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.92em;
  &:hover {
    color: ${({ theme }) => theme.brand.dark};
    text-decoration: none;
  }
`;

const NavContainer = styled(Box)`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  padding-left: 6.5%;
  padding-right: 6.5%;
`;

const LogoContainer = styled(Box)`
  width: 20%;
  height: 50%;
  display: flex;

  justify-content: space-between;
  align-items: center;

  ${mediumScreen} {
    display: none;
  }

  ${smallScreen} {
    display: none;
  }
`;

const PracticeContainer = styled(Box)`
  color: ${({ theme }) => theme.text.light};
  display: flex;
  text-align: center;
  font-weight: 400;
`;

const ButtonsContainer = styled.div`
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;

  ${mediumLargeScreen} {
    margin-right: 0;
  }
`;

const DashboardButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding-right: 40px;
`;

const DropDownContainer = styled(Box)`
  display: none;
  justify-content: flex-end;

  ${smallScreen} {
    display: flex;
  }
`;

const CustomTrackChangesIcon = styled(TrackChangesIcon)`
  padding-right: 5px;
`;

const LogoLabel = styled.span`
  text-decoration: none;
`;

const CustomButton = styled.a`
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 350;
  font-size: 1.12em;
  padding-right: 5%;

  &:hover {
    color: black;
    text-decoration: none;
  }

  ${smallScreen} {
    display: none;
  }
`;

const DonateButton = styled.a`
  color: white;
  text-decoration: none;
  align-items: center;
  font-weight: 350;
  font-size: 1.12em;
  padding: 5px 10px 5px 10px;
  background: #fe5c23;
  border-radius: 3px;
  margin-right: 10%;

  &:hover {
    text-decoration: none;
  }

  ${smallScreen} {
    display: none;
  }
`;

export {
  DashboardButton,
  NavContainer,
  LogoContainer,
  PracticeContainer,
  ButtonsContainer,
  DashboardButtonContainer,
  DropDownContainer,
  CustomTrackChangesIcon,
  LogoLabel,
  CustomButton,
  DonateButton,
};
