import styled from "styled-components";
import Trees from "../../assets/images/trees-image-2.png";
import Tobacco from "../../assets/images/tobacco-image.png";
import HIV from "../../assets/images/hiv.png";

import { smallScreen, mobileScreen } from "../../utils/breakpoints";

const Container = styled.div`
  margin: 0 auto;
  padding-top: 80px;
  margin-left: 7%;
  margin-right: 7%;
  height: 100%;
`;

const PageHeader = styled.div`
  margin-top: 10%;
  /* margin-bottom: 2%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${smallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

/*
 * Top Section
 */
const TopSection = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;
  /* margin-top: 10%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    margin-right: 15%;
  }

  ${smallScreen} {
    flex-direction: column;
    height: 100%;
  }

  ${mobileScreen} {
    svg {
      height: 150px;
      width: auto;
      margin-top: 5%;
    }
  }
`;

const HeaderText = styled.div`
  font-size: 36px;
  font-weight: 500;
  width: 26%;

  ${smallScreen} {
    font-size: 24px;
    width: 100%;
    margin-bottom: 10%;
  }
`;

const InfoSection = styled.div`
  color: #848484;
  width: 40%;
  margin-top: 2%;

  ${smallScreen} {
    width: 100%;
  }
`;

const Description = styled.div``;

const BottomSection = styled.div``;

const ArticlesSection = styled.div``;

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 65px;

  a {
    text-decoration: none;
    color: inherit;
  }

  ${mobileScreen} {
    flex-direction: column;
    gap: 35px;
  }
`;

const ArticleSectionTitle = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 3%;
  padding-left: 10px;

  ${mobileScreen} {
    margin-top: 15%;
    align-content: center;
  }
`;

const Article = styled.div`
  width: 16.5rem;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.2s ease-in-out 0s;

  &:hover {
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const ArticleTitle = styled.div`
  margin-top: 5%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

const ArticleDescription = styled.div`
  margin-top: 5%;
  font-size: 15px;
  line-height: 19px;
`;

const TreesImage = styled.div`
  height: 178px;
  width: 100%;
  background-image: url(${Trees});
  border-radius: 5px;
`;

const TobaccoImage = styled.div`
  height: 178px;
  width: 100%;
  background-image: url(${Tobacco});
  border-radius: 5px;
`;

const HIVImage = styled.div`
  height: 178px;
  width: 100%;
  background-image: url(${HIV});
  border-radius: 5px;
`;

export {
  Container,
  PageHeader,
  HeaderText,
  TopSection,
  InfoSection,
  Description,
  BottomSection,
  ArticlesSection,
  ArticlesContainer,
  ArticleSectionTitle,
  Article,
  ArticleTitle,
  ArticleDescription,
  TreesImage,
  TobaccoImage,
  HIVImage,
};
