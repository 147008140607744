import React from "react";
import { Container, LinksContainer, Link, Copyright } from "./styles";

const Footer = () => {
  const copyright = new Date().getFullYear();
  return (
    <Container>
      <LinksContainer>
        <Link href="/">Home</Link>
        <Link href="/volunteer">Volunteer</Link>
        <Link href="/pictures">Pictures</Link>
        <Link href="/contact">Contact</Link>
      </LinksContainer>
      <Copyright>&copy; Copyright {copyright}, MadaClinics.</Copyright>
    </Container>
  );
};

export default Footer;
