const Theme = {
  bg: {
    default: "#FFFFFF",
    reverse: "#16171A",
    wash: "#FAFAFA",
    divider: "#F6F7F8",
    border: "#EBECED",
    inactive: "#DFE7EF",
    highlightBlue: "#DDE9FE",
    lightBlue: "#e4f2f7",
  },
  brand: {
    lightDefault: "#1f51ad",
    default: "#194390",
    alt: "#6E8AFC",
    wash: "#DDE9FE",
    border: "#DDD9FF",
    accent: "#1DC989",
    dark: "#1A2E48",
  },
  text: {
    default: "#1A2E48",
    secondary: "#384047",
    alt: "#67717A",
    placeholder: "#7C8894",
    reverse: "#FFFFFF",
    light: "#888888",
    dark: "#555555",
    warning: "#E22F2F",
  },
  fontSizes: {
    tiny: "13px",
    small: "14px",
    normal: "16px",
    medium: "20px",
    large: "28px",
    huge: "32px",
  },
  danger: {
    lightDefault: "#FF0000",
    default: "#E22F2F",
    alt: "#E2197A",
    dark: "#85000C",
    wash: "#FFEDF6",
    border: "#FFCCE5",
  },
  success: {
    default: "#00B88B",
    alt: "#00D5BD",
    dark: "#00663C",
    wash: "#D9FFF2",
    border: "#9FF5D9",
  },
};

export default Theme;
