import React from "react";
import { withTheme } from "styled-components";
import landingImage from "../../assets/images/Big5.jpeg";

import {
  Container,
  ParallaxContainer,
  TopSection,
  SectionTitle,
  SectionText,
  SectionTextLeft,
  SectionTextRight,
  BottomSection,
  VideoContainer,
  ReadMoreSection,
  DonateSection,
  DonateText,
  DonateButton,
  DonateButtonLarge,
} from "./styles";

const Landing = () => {
  return (
    <>
      <ParallaxContainer image={landingImage} />
      <Container>
        <TopSection>
          <SectionTitle>Journey to visit Mavenitbao</SectionTitle>
          <SectionText>
            <SectionTextLeft>
              Mada Clinics is a non-governmental organization in rural Northern
              Madagascar. We offer free health care and education to over 40,000
              people each year living in Maventibao and nearby villages. Without
              intervention, local villagers would have limited access to health
              care as the closest facilities are over seven hours away. We rely
              solely on volunteers and donations for medical supplies and the
              functionality of our organization.
            </SectionTextLeft>
            <SectionTextRight>
              Mada Clinics welcomes volunteers from all backgrounds. If you
              would like to experience rural Madagascar and help us to provide
              for local citizens, we would love to hear from you! Please visit
              our volunteer page for more information.
            </SectionTextRight>
          </SectionText>
          <DonateButtonLarge href="/donation">Donate</DonateButtonLarge>
        </TopSection>
        <BottomSection>
          <VideoContainer>
            <iframe
              title="A Madagascan Adventure - MadaClinics NGO"
              width="560"
              height="315"
              allowFullScreen
              src="https://www.youtube.com/embed/aqJtNiecAVI"
            ></iframe>
          </VideoContainer>
          <ReadMoreSection>
            Read more about Madaclinics:{" "}
            <a href="/volunteer">
              <i>Volunteering with MadaClinics</i>
            </a>
          </ReadMoreSection>
          <DonateSection>
            <DonateText>
              Madaclinics operates solely on donations. These donation really
              make a impact.
            </DonateText>
            <DonateButton href="/donation">Donate</DonateButton>
          </DonateSection>
        </BottomSection>
      </Container>
    </>
  );
};

export default withTheme(Landing);
