import React from "react";

import {
  Container,
  PageHeader,
  HeaderText,
  HighlightText,
  HeaderDescription,
  TopSection,
  DonationInfo,
  AmountInfo,
  AmountInfoHeader,
  ListContainer,
  ListItem,
  Description,
  DonationForm,
  DonateSection,
  SubscriptionSection,
  CustomDonation,
} from "./styles";

const Donation = () => {
  return (
    <Container>
      <PageHeader>
        <HeaderText>
          Small Efforts Can Make A Big
          <HighlightText> Change</HighlightText>
        </HeaderText>
        <HeaderDescription>
          We seek out world-changers and difference-makers around the globe.
        </HeaderDescription>
      </PageHeader>
      <TopSection>
        <DonationInfo>
          <Description>
            Mada Clinics is entirely financed by donations so every dollar
            counts. Unlike some larger organizations where a large percentage of
            donations are lost in administrative fees, any money that is donated
            to Mada Clinics goes directly to helping the people living in remote
            regions in Northern Madagascar. In fact, our full financial records,
            containing all of our expenses, are available to anybody upon
            request.
          </Description>
          <AmountInfo>
            <AmountInfoHeader>How your donation helps:</AmountInfoHeader>
            <ListContainer>
              <ListItem>$1.50 = 1 mosquito net</ListItem>
              <ListItem>
                $5 = 5 doses of malaria medicine or 500 doses of parasite
                treatment
              </ListItem>
              <ListItem>
                $10 = One child’s school supplies for the entire year
              </ListItem>
              <ListItem>$20 = 5 new desks for the school</ListItem>
              <ListItem>$50 = 25 STI testing kits</ListItem>
              <ListItem>
                $150 = Medicine for the clinic for 1 week (treating on average
                250 patients per week)
              </ListItem>
              <ListItem>
                $300 = The total cost of building our new kindergarten
              </ListItem>
              <ListItem>
                $1,500 = The organization's entire expenses for 1 month
              </ListItem>
            </ListContainer>
          </AmountInfo>
        </DonationInfo>
        <DonationForm>
          <DonateSection>
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_top"
            >
              <input
                type="hidden"
                name="hosted_button_id"
                value="9GXJ7LXGKSH5N"
              />
              <CustomDonation>Custom Amount</CustomDonation>
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </DonateSection>
          <SubscriptionSection>
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="5Z5BBRGP6CN7W"
              />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="hidden"
                        name="on0"
                        value="Donation Amounts"
                      />
                      Monthly Amounts
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select name="os0">
                        <option value="$5/Month">
                          $5/Month : $5.00 USD - monthly
                        </option>
                        <option value="$25/Month">
                          $25/Month : $25.00 USD - monthly
                        </option>
                        <option value="$100/Month">
                          $100/Month : $100.00 USD - monthly
                        </option>
                      </select>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <input type="hidden" name="currency_code" value="USD" />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              ></img>
            </form>
          </SubscriptionSection>
        </DonationForm>
      </TopSection>
    </Container>
  );
};

export default Donation;
