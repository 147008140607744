import React from "react";

import {
  Container,
  // LeftImage,
  // MiddleImage,
  PageHeader,
  PageHeaderText,
  ImageContainer,
  // RightImage,
  TopSection,
  // MiddleSection,
  // BottomSection,
} from "./styles";
import GroupImage from "../../assets/images/group.jpeg";
import GroupImageTwo from "../../assets/images/Big5.jpeg";
import GroupImageThree from "../../assets/images/walking.jpeg";

const Pictures = () => {
  return (
    <Container>
      <PageHeader>
        <PageHeaderText>Pictures</PageHeaderText>
      </PageHeader>

      <TopSection>
        <ImageContainer>
          <img src={GroupImage} alt="Beach Resort" width="500" height="auto" />
          <img
            src={GroupImageTwo}
            alt="Beach Resort"
            width="500"
            height="auto"
          />
          <img
            src={GroupImageThree}
            alt="Beach Resort"
            width="500"
            height="auto"
          />
        </ImageContainer>
      </TopSection>
    </Container>
  );
};

export default Pictures;
