import React from "react";

import {
  Container,
  TopSection,
  PageHeader,
  PageHeaderText,
  Section,
  SeactionHeader,
} from "./styles";

const Volunteer = () => {
  return (
    <Container>
      <PageHeader>
        <PageHeaderText>Volunteer</PageHeaderText>
      </PageHeader>
      <TopSection>
        <Section>
          <SeactionHeader>Location</SeactionHeader>
          <ul>
            <li>
              Maventibao is the base for volunteers as this is where the clinic
              and school is located.
            </li>
            <li>
              This location is a 3 hour taxi brousse south of Diego Saurez
              (Antisiranana) and a 7 km hike off of the main road.
            </li>
            <li>
              Please contact the Mada Clinics team as early as possible in order
              to have transportation to the village organized.
            </li>
            <li>
              Learn what it’s like to live a simple life and make a difference
              by giving back to rural communities.
            </li>
          </ul>
        </Section>
        <Section>
          <SeactionHeader>Experience</SeactionHeader>
          <ul>
            <li>
              We provide an opportunity for travellers to experience rural
              Madagascar and help in our health clinic or school (teaching
              English and French) in any way that you can.
            </li>
            <li>
              Volunteers are based in Maventibao and provide a traveling health
              care service to nearby villages twice a week (7 kms and 10 kms
              away).
            </li>
            <li>We ask volunteers to stay for a minimum of 2 weeks.</li>
          </ul>
        </Section>
        <Section>
          <SeactionHeader>Accommodation</SeactionHeader>
          <ul>
            <li>
              Stay in an authentic Madagascar hut with a comfortable bed and
              full mosquito net. We have 5 huts available with single/double
              occupancy.
            </li>
            <li>All meals are provided by our in house Malagasy cook.</li>
          </ul>
        </Section>
        <Section>
          <SeactionHeader>Costs</SeactionHeader>
          <ul>
            <li>
              Maventibao is the base for volunteers as this is where the clinic
              and school is located.
            </li>
            <li>
              This location is a 3 hour taxi brousse south of Diego Saurez
              (Antisiranana) and a 7 km hike off of the main road.
            </li>
            <li>
              Please contact the Mada Clinics team as early as possible in order
              to have transportation to the village organized.
            </li>
            <li>
              Learn what it’s like to live a simple life and make a difference
              by giving back to rural communities.
            </li>
          </ul>
        </Section>
      </TopSection>
    </Container>
  );
};

export default Volunteer;
