import React from "react";
import { TextField } from "@material-ui/core";
import ContactImage from "../../assets/images/contact-us.svg";

import {
  Container,
  HeaderText,
  HeaderDescription,
  PageHeader,
  LeftColumn,
  BottomSection,
  RightColumn,
  CustomImage,
  CustomForm,
  ContactButton,
} from "./styles";

const Contact = () => {
  return (
    <Container>
      <PageHeader>
        <LeftColumn>
          <HeaderText>Contact Us</HeaderText>
          <HeaderDescription>
            We encourage you to contact us if you have any questions or
            comments. We will do our best to get back to you in a timely manner.
          </HeaderDescription>
          <CustomForm action="/contact" name="contact" method="post">
            <input type="hidden" name="form-name" value="contact" />
            <TextField variant="outlined" label="Full Name" name="name" />
            <TextField variant="outlined" label="Email" name="email" />
            <TextField
              variant="outlined"
              label="Message"
              name="message"
              multiline
              rows={10}
            />
            <ContactButton type="submit">Submit</ContactButton>
          </CustomForm>
        </LeftColumn>
        <RightColumn>
          <CustomImage src={ContactImage} alt="Contact Us" />
        </RightColumn>
      </PageHeader>
      <BottomSection></BottomSection>
    </Container>
  );
};

export default Contact;
