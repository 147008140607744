import styled from "styled-components";
import {
  smallScreen,
  // mediumScreen,
  // largeScreen,
} from "../../utils/breakpoints";

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-top: 80px;
  height: 100vh;

  ${smallScreen} {
    height: 150vh;
  }
`;

const LeftImage = styled.img`
  margin-left: 10%;
`;

const MiddleImage = styled.img`
  margin-left: 20%;
`;

const RightImage = styled.img`
  margin-left: 30%;
`;

const PageHeader = styled.div`
  background: #fafafa;
  padding-top: 2%;
  padding-bottom: 2%;
`;

const PageHeaderText = styled.div`
  font-size: 24px;
  margin-left: 15%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  img {
    margin: 5px;
  }

  ${smallScreen} {
    flex-direction: column;

    img {
      width: 100% !important;
      height: auto;
      margin: 0;
    }
  }
`;

/*
 * Top Section
 */
const TopSection = styled.div`
  position: relative;
  width: 100%;

  margin-top: 5%;
`;

/*
 * Middle Section
 */
const MiddleSection = styled.div`
  position: relative;
  width: 100%;
  height: 45vh;
`;

/*
 * Bottom Section
 */
const BottomSection = styled.div`
  position: relative;
  width: 100%;
  height: 55vh;
`;

export {
  Container,
  ImageContainer,
  PageHeader,
  PageHeaderText,
  LeftImage,
  MiddleImage,
  RightImage,
  TopSection,
  MiddleSection,
  BottomSection,
};
