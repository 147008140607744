import styled from "styled-components";
import {
  smallScreen,
  // mediumScreen,
  // largeScreen,
} from "../../utils/breakpoints";

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 2%;
  padding-top: 2%;
  background: grey;
  margin-top: 10%;

  ${smallScreen} {
    padding-top: 10%;
  }
`;

const Copyright = styled.div`
  color: white;
`;

const LinksContainer = styled.div`
  margin-right: 1%;
  display: flex;
  flex-direction: column;
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
`;

export { Container, Link, LinksContainer, Copyright };
