import styled from "styled-components";
import {
  smallScreen,
  // mediumScreen,
  // largeScreen,
} from "../../utils/breakpoints";

const Container = styled.div`
  margin: 0 auto;
  padding-top: 100px;
  margin-left: 7%;
  margin-right: 7%;
`;

const LeftColumn = styled.div``;
const RightColumn = styled.div``;

const PageHeader = styled.div`
  margin-top: 4%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${smallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 36px;
  font-weight: 500;

  ${smallScreen} {
    font-size: 24px;
    width: 100%;
    margin-bottom: 10%;
  }
`;

const HeaderDescription = styled.div`
  margin-top: 5%;
  font-size: 16px;
  font-weight: 500;
  width: 65%;

  ${smallScreen} {
    width: 100%;
  }
`;

const CustomImage = styled.img`
  height: 300px;
  width: auto;
  margin-right: 5%;

  ${smallScreen} {
    display: none;
  }
`;

/*
 * Top Section
 */
const TopSection = styled.div`
  position: relative;
  width: 100%;
  height: 45vh;
`;

/*
 * Bottom Section
 */
const BottomSection = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;

  ${smallScreen} {
    width: 100%;
    height: 85vh;
  }
`;

const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 65%;
  div {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ${smallScreen} {
    width: 100%;
  }
`;

const ContactButton = styled.button`
  width: 110px;
  height: 40px;
  margin-left: 40%;
  margin-top: 5%;
  color: white;
  font-style: normal;
  font-weight: 600;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(254, 92, 35, 0.4);
  border-radius: 8px;
  border: 2px solid #fe5c23;
  color: black;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(254, 92, 35, 0.51);
  }

  ${smallScreen} {
    margin-left: 30%;
    margin-bottom: 10%;
  }
`;

export {
  Container,
  PageHeader,
  HeaderDescription,
  HeaderText,
  TopSection,
  BottomSection,
  LeftColumn,
  RightColumn,
  CustomImage,
  CustomForm,
  ContactButton,
};
