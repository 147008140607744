const handleClose = (event, anchorRef, setOpen) => {
  if (anchorRef.current && anchorRef.current.contains(event.target)) {
    return;
  }

  setOpen(false);
};

const handleListKeyDown = (event, setOpen) => {
  if (event.key === "Tab") {
    event.preventDefault();
    setOpen(false);
  }
};

export { handleClose, handleListKeyDown };
