import styled from "styled-components";
import landingImage from "../../assets/images/Big5.jpeg";
import {
  smallScreen,
  // mediumScreen,
  // largeScreen,
} from "../../utils/breakpoints";

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const ParallaxContainer = styled.div`
  /* The image used */
  /* background-image: url(${landingImage}); */
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  background-image: ${({ image }) => `url(${image})`};
  /* Set a specific height */
  min-height: 700px;
  height: 100%;
  width: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${smallScreen} {
    min-height: 20px;
    height: 300px;
    background-position: 55% -10px;
    background-size: 500px auto;
  }
`;

/*
 * Top Section
 */
const TopSection = styled.div`
  position: relative;
  width: 100%;
  height: 45vh;

  ${smallScreen} {
    height: 100vh;
  }
`;

const SectionTitle = styled.div`
  font-size: 35px;
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
`;

const SectionText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #848484;

  ${smallScreen} {
    margin-left: 8%;
    flex-direction: column;
  }
`;

const SectionTextLeft = styled.div`
  margin-right: 5%;
  width: 25%;
  ${smallScreen} {
    width: 80%;
  }
`;

const SectionTextRight = styled.div`
  width: 25%;

  ${smallScreen} {
    margin-top: 10%;
    width: 80%;
  }
`;

/*
 * Bottom Section
 */
const BottomSection = styled.div`
  position: relative;
  width: 100%;
  height: 55vh;
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReadMoreSection = styled.div`
  text-align: center;
  margin-bottom: 5%;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const DonateSection = styled.div`
  text-align: center;
  background: #222222;
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const DonateText = styled.div`
  text-align: center;
  background: #222222;
  color: white;
`;

const DonateButton = styled.a`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  color: black;
  border-radius: 2px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    outline: none;
  }

  ${smallScreen} {
    margin-right: 5%;
    padding-top: 15px;
  }
`;

const DonateButtonLarge = styled.a`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 20px;
  background: #fe5c23;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  text-decoration: none;
  width: 95px;
  height: 35px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-self: center;
  margin: 0 auto;
  margin-top: 3%;
  transition: all 0.2s ease-in-out 0s;
  box-shadow: 6px 5px 6px rgba(255, 165, 134, 0.38);

  &:hover {
    background: #f8541b;
    cursor: pointer;
    outline: none;
    box-shadow: 3px 3px 6px rgba(255, 165, 134, 0.38);
  }

  ${smallScreen} {
    margin-top: 15%;
  }
`;

export {
  Container,
  ParallaxContainer,
  TopSection,
  SectionTitle,
  SectionText,
  SectionTextLeft,
  SectionTextRight,
  BottomSection,
  VideoContainer,
  ReadMoreSection,
  DonateSection,
  DonateText,
  DonateButton,
  DonateButtonLarge,
};
