export const largeScreenBreakpoint = 1577;
export const largeScreen = `@media all and (max-width: ${largeScreenBreakpoint}px)`;

export const mediumLargeScreenBreakpoint = 1350;
export const mediumLargeScreen = `@media all and (max-width: ${mediumLargeScreenBreakpoint}px)`;

export const mediumScreenBreakpoint = 1100;
export const mediumScreen = `@media all and (max-width: ${mediumScreenBreakpoint}px)`;

export const smallScreenBreakpoint = 920;
export const smallScreen = `@media all and (max-width: ${smallScreenBreakpoint}px)`;

export const mobileScreenBreakpoint = 715;
export const mobileScreen = `@media all and (max-width: ${mobileScreenBreakpoint}px)`;

export const reallySmallScreenBreakpoint = 350;
export const reallySmallScreen = `@media all and (max-width: ${reallySmallScreenBreakpoint}px)`;
