import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";

const MenuButton = styled(Button)`
  color: ${({ theme }) => theme.brand.dark};
`;

const MenuLink = styled.a`
  text-decoration: none;
  color: ${({ theme, isBlue }) => (isBlue ? theme.brand.dark : "inherit")};
  font-size: ${({ theme, isBlue }) =>
    isBlue ? theme.fontSizes.small : theme.fontSizes.normal};
  &:hover {
    text-decoration: none;
  }
`;

const GrowContainer = styled(Grow)`
  transform-origin: ${({ placement }) =>
    placement === "bottom" ? "center top" : "center bottom"};
`;

const AccountEditContainer = styled(ListItem)`
  padding-top: 0;
`;

const UsernameListItem = styled(ListItem)`
  padding-bottom: 0;
  outline-width: 0;
`;

const PracticeListItem = styled(ListItem)`
  padding-top: 0;
  outline-width: 0;
`;

const UsernameLabel = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  padding-top: 15px;
`;

const SectionLabel = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const SignOutButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export {
  MenuButton,
  MenuLink,
  GrowContainer,
  AccountEditContainer,
  UsernameListItem,
  PracticeListItem,
  UsernameLabel,
  SectionLabel,
  SignOutButton,
};
