import styled from "styled-components";

import {
  smallScreen,
  // mediumScreen,
  // largeScreen,
} from "../../utils/breakpoints";

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-top: 85px;
`;

/*
 * Top Section
 */
const TopSection = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: 8%;
  margin-left: 15%;
  width: 50%;

  ${smallScreen} {
    width: 80%;
    margin-left: 5%;
    height: 150vh;
  }
`;

const PageHeader = styled.div`
  background: #fafafa;
  padding-top: 2%;
  padding-bottom: 2%;
`;

const PageHeaderText = styled.div`
  font-size: 24px;
  margin-left: 15%;
`;

const Section = styled.div`
  margin: 0 auto;
  li {
    color: #848484;
  }
`;
const SeactionHeader = styled.div``;

export {
  Container,
  TopSection,
  PageHeader,
  PageHeaderText,
  Section,
  SeactionHeader,
};
