import styled from "styled-components";
import {
  smallScreen,
  // mediumScreen,
  // largeScreen,
} from "../../utils/breakpoints";

const Container = styled.div`
  margin: 0 auto;
  padding-top: 80px;
  margin-left: 7%;
  margin-right: 7%;
  /* height: 150vh; */
  height: 100%;
`;

const PageHeader = styled.div`
  margin-top: 4%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${smallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

const EmailInput = styled.input`
  height: 35px;
  font-size: 20px;
  margin-top: 1%;
  background: #ffffff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.21);
  border-radius: 5px;
  width: 232px;
  height: 47px;
  border: none;
  padding-left: 2%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  color: #7b7b7b;
`;

const HeaderText = styled.div`
  font-size: 36px;
  font-weight: 500;
  width: 26%;

  ${smallScreen} {
    font-size: 24px;
    width: 100%;
    margin-bottom: 10%;
  }
`;

const HeaderDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const HighlightText = styled.span`
  color: #fe5c23;
`;

/*
 * Top Section
 */
const TopSection = styled.div`
  position: relative;
  width: 100%;
  height: 65vh;
  margin-top: 4%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${smallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

const Description = styled.div``;

const DonationForm = styled.div`
  width: 690px;
  height: 200px;
  box-sizing: border-box;
  background: #ececec;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  display: flex;
  flex-direction: row;

  ${smallScreen} {
    width: 100%;
    margin-top: 5%;
    height: 100%;
    flex-direction: column;
  }
`;

const AmountButton = styled.button`
  margin-top: 10px;
  width: 120px;
  height: 53px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  border: ${({ isSelected }) => (isSelected ? "2px solid #fe5c23" : "none")};
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #7b7b7b;

  &:hover {
    cursor: pointer;
  }
`;

const AmountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5%;
`;

/*
 * Bottom Section
 */
const BottomSection = styled.div`
  position: relative;
  width: 100%;
  height: 55vh;
`;

const DonateButton = styled.button`
  width: 110px;
  height: 40px;
  margin-left: 40%;
  margin-top: 5%;
  color: white;
  font-style: normal;
  font-weight: 600;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(254, 92, 35, 0.4);
  border-radius: 8px;
  border: 2px solid #fe5c23;
  color: black;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(254, 92, 35, 0.51);
  }

  ${smallScreen} {
    margin-left: 30%;
    margin-bottom: 10%;
  }
`;

const SectionLabel = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #7b7b7b;
`;

const DonationInput = styled.input`
  height: 35px;
  font-size: 20px;
  margin-top: 1%;
  background: #ffffff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.21);
  border-radius: 5px;
  width: 232px;
  height: 47px;
  border: none;
  padding-left: 5%;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='40px' width='85px'><text x='2' y='31' fill='gray' font-size='24' font-family='arial'>$</text></svg>");
  background-repeat: no-repeat;
  color: #7b7b7b;

  ${smallScreen} {
    background-image: none;
  }
`;

const DonateSection = styled.div`
  /* margin-top: 5%; */
`;

const CustomDonation = styled.div`
  margin-top: 2%;
  margin-bottom: 22%;
  ${smallScreen} {
    margin-bottom: 5%;
  }
`;

const SubscriptionSection = styled.div`
  margin-left: 20%;

  ${smallScreen} {
    margin-left: 0%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
`;

const InputContainer = styled.div`
  margin-top: 10%;
`;

const DonationInfo = styled.div`
  color: #848484;
  width: 40%;

  ${smallScreen} {
    width: 100%;
  }
`;

const AmountInfo = styled.div`
  margin-top: 5%;
`;
const AmountInfoHeader = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
`;
const ListContainer = styled.ul`
  /* color: #848484; */
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
`;
const ListItem = styled.li`
  /* margin-top: 10%; */
`;

export {
  Container,
  EmailInput,
  TopSection,
  Description,
  DonationInfo,
  AmountInfo,
  AmountInfoHeader,
  ListContainer,
  ListItem,
  PageHeader,
  HeaderText,
  HighlightText,
  HeaderDescription,
  BottomSection,
  DonateButton,
  DonateSection,
  DonationInput,
  DonationForm,
  AmountButton,
  AmountsContainer,
  InputContainer,
  SectionLabel,
  SubscriptionSection,
  CustomDonation,
};
