import React from "react";
import { Route, Switch } from "react-router-dom";
import theme from "./utils/theme";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import Navbar from "./components/Navbar";
import Landing from "./pages/Landing";
import Donation from "./pages/Donation";
import Initiatives from "./pages/Initiatives";
import Contact from "./pages/Contact";
import Volunteer from "./pages/Volunteer";
import Pictures from "./pages/Pictures";
import Footer from "./components/Footer";

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/volunteer" exact component={Volunteer} />
          <Route path="/pictures" exact component={Pictures} />
          <Route path="/donation" exact component={Donation} />
          <Route path="/initiatives" exact component={Initiatives} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
