import {
  Container,
  PageHeader,
  HeaderText,
  TopSection,
  InfoSection,
  Description,
  BottomSection,
  ArticlesSection,
  ArticleSectionTitle,
  Article,
  ArticleTitle,
  ArticleDescription,
  ArticlesContainer,
  TreesImage,
  TobaccoImage,
  HIVImage,
} from "./styles";

import { ReactComponent as ResearchIcon } from "../../assets/images/beakers-and-researcher.svg";
import { ReactComponent as ManInBoat } from "../../assets/images/man-in-boat-2.svg";

const Resarch = () => {
  return (
    <Container>
      <PageHeader>
        <HeaderText>Initiatives</HeaderText>
      </PageHeader>
      <TopSection>
        <InfoSection>
          <Description>
            In addition to our focus on providing free clinical care and
            education, we are also heavily focused on improving the quality of
            medical care in rural Madagascar & the public health of the
            communities we serve through our research initiatives highlighted
            below.
          </Description>
        </InfoSection>
        <ResearchIcon />
      </TopSection>
      <BottomSection>
        <ArticlesSection>
          <ArticleSectionTitle>Articles</ArticleSectionTitle>
          <ArticlesContainer>
            <a
              href="https://www.joghr.org/article/22123-schistosomiasis-prevalence-and-low-cost-diagnostics-in-rural-northwestern-madagascar-a-pilot-study?auth_token=r1mmAeC8NKELT7PryN_-"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Article>
                <ManInBoat id="man-in-boat" />
                <ArticleTitle>Schistosomiasis</ArticleTitle>
                <ArticleDescription>
                  Schistosomiasis is a waterborne parasitic infectious disease
                  which causes anemia, bladder cancer, liver dysfunction and a
                  host of other ailments contributing to an estimated 200,000
                  deaths each year.
                </ArticleDescription>
              </Article>
            </a>
            <a
              href="https://www.hindawi.com/journals/crips/2021/6661943/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Article>
                <TreesImage id="trees-image" />
                <ArticleTitle>Psychiatry and Preventive Medicine</ArticleTitle>
                <ArticleDescription>
                  Traditional psychiatric care is nonexistent in rural Northern
                  Madagascar. Yet, owing in large part to high mortality rates
                  and harsh living conditions, psychiatric complaints are common
                  amongst the patients treated at Mada Clinics.
                </ArticleDescription>
              </Article>
            </a>
            <a
              href="https://drive.google.com/file/d/1K4o7xwsl0IQu6VuOaJixEK0uIkTwxHyS/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Article>
                <TobaccoImage />
                <ArticleTitle>Tobacco Use</ArticleTitle>
                <ArticleDescription>
                  Madagascar has the highest rate of tobacco use in Sub-Saharan
                  Africa. The majority of males in the region served by Mada
                  Clinics use tobacco products. In addition, the majority of
                  villagers in rural Northern Madagascar have a parasitic worm
                  infection called schistosomiasis.
                </ArticleDescription>
              </Article>
            </a>
            <Article>
              <HIVImage />
              <ArticleTitle>HIV (Link unavailable)</ArticleTitle>
              <ArticleDescription>
                Madagascar has some of the highest sexually transmitted
                infection (STI) rates in the world. One-third of patients who
                present to Mada Clinics are diagnosed with and treated for a
                STI. Despite high STI rates, no options for HIV testing or
                treatment exist in rural Northern Madagascar.
              </ArticleDescription>
            </Article>
          </ArticlesContainer>
        </ArticlesSection>
      </BottomSection>
    </Container>
  );
};

export default Resarch;
