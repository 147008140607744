import React, { useEffect, useRef, useState } from "react";
import { withTheme } from "styled-components";
import { StylesProvider } from "@material-ui/core/styles";

import { handleClose } from "../../../helpers/dropdown";
import { handleListKeyDown } from "../../../helpers/dropdown";

// Custom Styles
import { MenuButton, MenuLink, GrowContainer } from "./styles";

// Material UI Styles
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(1),
  },
}));

const DropdownMenu = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <StylesProvider injectFirst>
      <Box display="flex" pr={10} justifyContent="flex-end" width="20%">
        <div className={classes.root}>
          <div>
            <MenuButton
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={() => setOpen((prevOpen) => !prevOpen)}
              size="large"
              startIcon={<DashboardOutlinedIcon />}
            >
              MENU
            </MenuButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {/* Destructuring props from Popper component 
                  https://material-ui.com/components/menus/#MenuListComposition.js
              */}
              {({ TransitionProps, placement }) => (
                <GrowContainer {...TransitionProps} placement={placement}>
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(event) =>
                        handleClose(event, anchorRef, setOpen)
                      }
                    >
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={(event) => handleListKeyDown(event, setOpen)}
                      >
                        <MenuLink href="/">
                          <MenuItem
                            onClick={(event) =>
                              handleClose(event, anchorRef, setOpen)
                            }
                          >
                            Home
                          </MenuItem>
                        </MenuLink>

                        <MenuLink href="/volunteer">
                          <MenuItem
                            onClick={(event) =>
                              handleClose(event, anchorRef, setOpen)
                            }
                          >
                            Volunteer
                          </MenuItem>
                        </MenuLink>

                        <MenuLink href="/pictures">
                          <MenuItem
                            onClick={(event) =>
                              handleClose(event, anchorRef, setOpen)
                            }
                          >
                            Pictures
                          </MenuItem>
                        </MenuLink>
                        <MenuLink href="/initiatives">
                          <MenuItem
                            onClick={(event) =>
                              handleClose(event, anchorRef, setOpen)
                            }
                          >
                            Initiatives
                          </MenuItem>
                        </MenuLink>
                        {/* <MenuLink href="/donation">
                          <MenuItem
                            onClick={(event) =>
                              handleClose(event, anchorRef, setOpen)
                            }
                          >
                            Donation
                          </MenuItem>
                        </MenuLink> */}
                        <MenuLink href="/contact">
                          <MenuItem
                            onClick={(event) =>
                              handleClose(event, anchorRef, setOpen)
                            }
                          >
                            Contact
                          </MenuItem>
                        </MenuLink>
                        <MenuLink href="/donation">
                          <MenuItem
                            onClick={(event) =>
                              handleClose(event, anchorRef, setOpen)
                            }
                          >
                            Donate
                          </MenuItem>
                        </MenuLink>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </GrowContainer>
              )}
            </Popper>
          </div>
        </div>
      </Box>
    </StylesProvider>
  );
};

export default withTheme(DropdownMenu);
